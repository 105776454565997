@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-color: #101010
}

@font-face {
    font-family: "Inter";
    src: url("../public/fonts/Inter-Bold.ttf");
  }